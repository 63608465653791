<template>
     <div>
        <router-view></router-view>
    </div>
</template>

<script>



export default {
    name: 'NodeprojectProducts',
    components: {  },
    directives: {  },
    data() {
        return {
           
        };
    },
    mounted() {
        
    },
    computed:{
     
    },
    methods: {
       
    },
};
</script>

<style  scoped>


</style>